import { isMobile } from 'react-device-detect';
import { State } from 'app/types/cart';

export const INITIAL_STATE: State = {
  cart: [],
  totalItems: 0,
  totalPrice: 0,
  priceDiscount: 0,
  percentageDiscount: 0,
  manualPrice: 0,
  highlightedProduct: null,
  professionals: [],
  voucher: null,
};
export const SHOW_TOP_BAR = false;

export const HEADER_HEIGHT_MOBILE = SHOW_TOP_BAR ? 56 + 51 + 20 : 56 + 51;
export const HEADER_HEIGHT_DESKTOP = SHOW_TOP_BAR ? 72 + 51 + 20 : 72 + 51;

export const DERMA_HEADER_HEIGHT_MOBILE = 68;
export const DERMA_HEADER_HEIGHT_DESKTOP = 80;

export function headerHeight() {
  return isMobile ? HEADER_HEIGHT_MOBILE : HEADER_HEIGHT_DESKTOP;
}
